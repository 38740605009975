<script lang="ts" setup>
import type { ComponentBlockPartnerFragment } from "~~/types/graphql";

import { Enum_Componentblockpartner_Variation } from "~~/types/graphql";

import Arrow from "@/assets/icons/arrow.svg?component";

const props = defineProps<{
  PartnerVariation: Enum_Componentblockpartner_Variation;
  PartnerLink: ComponentBlockPartnerFragment["PartnerLink"];
  PartnerHeadline: ComponentBlockPartnerFragment["PartnerHeadline"];
  PartnerPartnerLinks: ComponentBlockPartnerFragment["PartnerPartnerLinks"];
  PartnerText: ComponentBlockPartnerFragment["PartnerText"];
  PartnerSubheadline: ComponentBlockPartnerFragment["PartnerSubheadline"];
}>();
</script>
<template>
  <section class="partner">
    <layout-block
      v-if="
        props.PartnerHeadline || props.PartnerSubheadline || props.PartnerText
      "
      :title="props.PartnerSubheadline"
      :link="props.PartnerLink?.data?.attributes?.Slug"
      class="partner md:bg-transparent"
      :class="{
        'bg-light-gray':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Dark,
      }"
    >
      <div class="grid gap-5.4 md:grid-cols-12">
        <h2
          v-if="props.PartnerHeadline"
          class="hyphens-auto font-pixel text-h2-s md:col-start-7 md:col-end-13 md:row-start-1 md:text-h2 lg:col-start-8 lg:col-end-12"
        >
          {{ props.PartnerHeadline }}
        </h2>
        <base-text
          v-if="props.PartnerText"
          :Text="props.PartnerText"
          class="md:col-span-6 md:col-start-1 md:col-end-7 md:row-start-1 lg:col-start-1 lg:col-end-6"
        />
      </div>
    </layout-block>
    <div
      :class="{
        'bg-integer-black':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Dark,
        'bg-integer-white':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Light,
      }"
    >
      <div class="partner-links container justify-around md:flex">
        <nuxt-link
          v-for="(partnerLink, index) in props.PartnerPartnerLinks"
          :key="`${partnerLink?.__typename}-${index}`"
          :to="partnerLink?.url"
          :title="partnerLink?.title"
          target="_blank"
          class="default-transition group relative grid place-content-center p-20 hover:grayscale-0 md:grayscale"
        >
          <base-button
            class="absolute right-5.2 top-5.2 -rotate-45 text-integer-white md:hidden"
            only-icon
            transparent
            :aria-label="partnerLink?.title"
            ><Arrow
          /></base-button>
          <base-image
            v-bind="partnerLink?.image?.data?.attributes"
            class="max-w-xs"
            sizes="20rem"
          />
        </nuxt-link>
      </div>
    </div>
  </section>
</template>
